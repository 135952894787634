<template>
  <b-card>
    <div
      v-for="(item, index) in changelog.reverse()"
      :key="item.version"
    >
      <hr v-if="index !== 0" />
      <h5>{{ item.version }}</h5>
      <ul>
        <li
          v-for="change in item.changes"
          :key="item.version + change"
        >
          {{ change }}
        </li>
      </ul>
    </div>
  </b-card>
</template>

<script>
export default {
  computed: {
    changelog() {
      return [
        {
          version: '0.9.0',
          changes: [
            'Add    : Docking comments field',
            'Add    : Measurement units for main parameters',
            'Add    : Hogging/Sagging calculation & labels',
            'Add    : Last measurements on sensors tooltip',
            'Add    : Error display on sensor last measurements',
            'Add    : Flattened docking measurements',
            'Add    : Support for multiple vessels per docking',
            'Update : Graph titles',
            'Update : Graph line color',
            'Update : Show sensor retwist values instead of raw',
            'Update : Format measurements to two decimal places',
            'Update : Tooltip colors',
            'Fix    : Translations',
          ],
        },
        {
          version: '1.0.0',
          changes: [
            'Add    : iLevelMeasurement parameter for every sensor',
            'Add    : Docking dashboard header',
            'Add    : Docking dashboard start & stop buttons',
            'Add    : Port and starboard deflection calculation',
            'Add    : Docking deflection separation to port & starboard',
            'Add    : Active docking validation on insert',
            'Add    : Apply active docking on new parsed measurements',
            'Add    : Start docking endpoint',
            'Add    : End docking endpoint',
            'Add    : Measurement units on dashboard',
            'Add    : Raw Measurement badge on dashboard',
            'Add    : Apply iLevelMeasurement to retwist calculations',
            'Add    : Characterize sensor as active algorithm',
            'Add    : Active sensors coloring on dashboard',
            'Update : Removed point boolean from sensor fields',
            'Update : UI default to menu collapsed',
            'Update : Dark theme secondary color',
            'Update : Removed Start Date field from dockings modal',
            'Update : Made Vessel IMO an optional field',
            'Update : Removed duplicate entity buttons',
            'Update : Optimized measurements unique constraint on database',
          ],
        },
        {
          version: '1.1.0',
          changes: [
            'Add    : Validation for non existing sensors',
            'Add    : Separate deflection for port & starboard in dashboard',
            'Add    : Roles user interfaces separation',
            'Update : API polling optimization',
            'Update : Reduced dashboard height',
            'Fix    : Decimal fields validation',
          ],
        },
        // {
        //   version: '1.1.1',
        //   changes: [
        //     'Add    : Set email unique constraint for users',
        //     'Add    : Delete item confirmation',
        //     'Add    : End docking modal confirmation',
        //     'Update : Dashboard cards repositioning',
        //     'Update : Decimal format locale',
        //     'Fix    : Allow negative numbers on parameters',
        //     'Fix    : Calibration Value save',
        //   ],
        // },
        // {
        //   version: '1.1.2',
        //   changes: [
        //     'Add    : Cascade delete on dockings',
        //     'Add    : Vessel name on dashboard',
        //     'Add    : Switch between raw and degree values',
        //     'Add    : Main and station parameters separation',
        //     'Add    : Metric symbol on last measurements',
        //     'Add    : Calibration value add to last measurements',
        //     'Add    : Retwist calculation for last measurements',
        //     'Add    : Persist retwist values in database',
        //     'Add    : Retwist values on measurements export',
        //     'Add    : Responsive table functionality',
        //     'Add    : Filters Open by default on measurements screen',
        //     'Add    : Apply refresh interval parameter on dashboard',
        //     'Add    : Scheduler framework based on quartz',
        //     'Add    : Set sensors inactive based on latency minutes parameter',
        //     'Update : Degree symbol for retwist',
        //     'Update : Dynamic graph upper and lower limit',
        //     'Update : Removed min max values fields on main parameters',
        //     'Update : Round degrees to 1 decimal place, and meters to 2',
        //     'Fix    : Port & starboard deflection calculation based on absolute values',
        //     'Fix    : Port deflection calculation',
        //     'Fix    : Null check for calibration value add to position',
        //     'Fix    : Graph update timing',
        //     'Fix    : Decimal validation',
        //   ],
        // },
        // {
        //   version: '1.1.2a',
        //   changes: [
        //     'Add    : Station parameters',
        //     'Update : Measurement filters',
        //     'Update : Title fields rename',
        //     'Update : Main parameters entries',
        //     'Update : User group rights',
        //     'Fix    : Retwist decimal rounding',
        //     'Fix    : Metric units on dashboard',
        //   ],
        // },
        // {
        //   version: '1.1.2b',
        //   changes: [
        //     'Update : Water level switch',
        //     'Update : Graph limits',
        //     'Update : System parameters labels',
        //     'Update : Removed calibration value format',
        //     'Fix    : sagging / hogging label',
        //   ],
        // },
        // {
        //   version: '1.1.3',
        //   changes: [
        //     'Add    : Upper and lower limits on charts',
        //     'Add    : Dynamic limits on charts based on Maximum Defined Deflection (cm) parameter',
        //     'Add    : Alert Defined Deflection (cm) parameter',
        //     'Add    : Maximum Trim Value parameter alert',
        //     'Add    : Maximum List Value parameter alert',
        //     'Add    : Port Maximum Deflection Values exceeded - WARNING',
        //     'Add    : Starboard Maximum Deflection Values exceeded - WARNING',
        //     'Add    : Ιnfo cards indication on exceeded maximum values',
        //     'Add    : Total Water Level Low alert',
        //     'Add    : Sensor Water Level Low alert',
        //     'Add    : End docking modal confirmation',
        //     'Update : Metric unit on charts yaxes',
        //     'Update : Custom water level switch styling',
        //     'Update : Measurements table headers',
        //     'Update : Sag / Hog evaluation excluding corner sensors',
        //     'Fix    : List / trim calculation',
        //     'Fix    : Clear polling for dashboard after routing',
        //   ],
        // },
        // {
        //   version: '1.1.4',
        //   changes: [
        //     'Add    : Save measurement raw value',
        //     'Add    : Persist to database Maximum Trim Value parameter alert notification',
        //     'Add    : Persist to database Maximum List Value parameter alert notification',
        //     'Add    : Persist to database Port Maximum Deflection Values exceeded alert notification',
        //     'Add    : Persist to database Starboard Maximum Deflection Values alert notification',
        //     'Add    : Persist to database Total Water Level Low alert notification',
        //     'Add    : Persist to database Sensor Water Level Low alert notification',
        //     'Add    : Validation for duplicate notifications',
        //     'Add    : Dismiss notification functionality',
        //     'Update : Show warnings only if docking has started',
        //     'Update : Position labels',
        //     'Fix    : Measurement water level value on Database',
        //   ],
        // },
        // {
        //   version: '1.1.5',
        //   changes: [
        //     'Add    : Dismissible alerts',
        //     'Fix    : Trim & list calculation',
        //   ],
        // },
        // {
        //   version: '1.1.6',
        //   changes: [
        //     'Add    : No retwist display for sensors below low water level threshold',
        //     'Add    : Display as inactive sensors below low water level threshold',
        //   ],
        // },
        // {
        //   version: '1.1.7',
        //   changes: [
        //     'Add    : PWA functionality',
        //     'Add    : Dependencies update and optimization',
        //   ],
        // },
        // {
        //   version: '1.1.8',
        //   changes: [
        //     'Add    : Display measurements in feet option',
        //     'Add    : Node Level Error Threshold (m) parameter',
        //     'Add    : Docking relation to notifications',
        //     'Add    : Notification table with filters',
        //     'Add    : Collapsible warnings panel',
        //     'Update : Reverse sensors order in charts',
        //     'Update : Docking required for measurements list results',
        //     'Update : Measurements list decimal places - three for raw values, one for retwist',
        //     'Update : Port and starboard charts in two columns',
        //     'Update : Moved warnings to dashboard bottom',
        //     'Update : Removed separate sensor alerts',
        //     'Update : Code cleanup',
        //     'Fix    : Charts last dot crop',
        //   ],
        // },
        // {
        //   version: '1.1.9',
        //   changes: [
        //     'Backend - Spring Boot upgrade to 2.7.6',
        //     'Frontend - npm dependencies upgrades',
        //     'Frontend - Bootstrap upgrade',
        //     'Add    : MIS upload functionality',
        //     'Add    : Single side operation functionality',
        //     'Add    : Single side operation manual calculation option',
        //     'Add    : Port & Starboard side inactive validation & notifications',
        //     'Add    : Virtual sensor calculation',
        //   ],
        // },
        {
          version: '1.2.0',
          changes: [
            'Update : Single side operation UI improvements',
            'Update : Trim calculation side operation UI improvements',
            'Fix    : Single side operation manual selection',
            'Fix    : Zero retwist on UI',
          ],
        },
        // {
        //   version: '1.2.1',
        //   changes: [
        //     'Add    : Both Sides Inactive warning',
        //     'Update : Do not show last values for inactive sensors',
        //     'Update : Force docking mode manual update',
        //     'Update : Show last measurements even when calculations are not possible',
        //     'Update : Disable chart for incatiive sensors',
        //     'Fix    : Docking deletion on some occassions',
        //     'Fix    : Single side calculation decimal precision',
        //     'Fix    : Single side calculation auto side change',
        //     'Fix    : List columns export',
        //   ],
        // },
        // {
        //   version: '1.2.2',
        //   changes: [
        //     'Add    : No retwist calculations for other side on single side docking mode',
        //     'Add    : Save docking mode upon page refresh',
        //     'Add    : Sensor to inactive state notification',
        //     'Add    : Align charts with docking mode',
        //     'Update : 3 decimal places for feet values',
        //     'Update : Removed can assign columns',
        //     'Update : Removed obsolete role privileges',
        //     'Fix    : Chart covering whole row width on some occasions',
        //   ],
        // },
        // {
        //   version: '1.2.3',
        //   changes: ['Fix    : Restore docking mode'],
        // },
        // {
        //   version: '1.2.4',
        //   changes: [
        //     'Add    : Measurement unit option on measurements screen',
        //     'Fix    : Measurement history save for side calculation',
        //   ],
        // },
        // {
        //   version: '1.2.5',
        //   date: '2023-07-11',
        //   changes: [
        //     'Add: Spring Boot upgrade to 2.7.13',
        //     'Add: VueJs upgrade',
        //     'Add: Angle charts',
        //     'Add: Max docking parameters on docking dashboard',
        //     'Fix: Showing all sensors on deflection charts',
        //   ],
        // },
        // {
        //   version: '1.2.6',
        //   date: '2023-07-15',
        //   changes: [
        //     'Add: Angle Charts rerendering without page refresh',
        //     'Add: Angle Charts conditional rendering on side mode',
        //     'Add: Sensors below water low level threshold warning',
        //     'Add: Sensors inactive warning',
        //     'Update : Dark layout appearance',
        //     'Update : Charts top & bottom paddings',
        //     'Update : Code cleanup',
        //     'Update : Reversed angle charts bent',
        //     'Update : Removed favorites shortcut',
        //     'Update : Angle charts labels',
        //     'Update : Max deflection rounding value',
        //     'Update : User entity fields',
        //     'Fix: Deflection values in Measurements Log to feet',
        //     'Fix: Angle Charts paddings',
        //   ],
        // },
        // {
        //   version: '1.2.7',
        //   date: '2023-07-19',
        //   changes: [
        //     'Update : Reverted angle charts bent',
        //     'Fix: Sensors inactive / low water threshold warning messages',
        //   ],
        // },
        // {
        //   version: '1.2.8',
        //   date: '2023-09-17',
        //   changes: [
        //     'Update : Reverted angle charts axis',
        //   ],
        // },
        // {
        //   version: '1.2.9',
        //   date: '2023-10-15',
        //   changes: [
        //     'Add: Active docking validation exception for new docking insertion',
        //     'Add: Notification for inactive sensor',
        //     'Add: Support for inches on docking dashboard',
        //     'Add: Support for inches on measurements history',
        //     'Add: Stop sound functionality',
        //     'Add: Spring Boot upgrade to 2.7.16',
        //     'Add: Support for JDK 17',
        //     'Update : Angle chart color for dark mode',
        //     'Update : Rounded measurements for angle charts',
        //     'Fix: Sensor Water Level Low warning not saved on notifications on some occasions',
        //   ],
        // },
        {
          version: '1.3.0',
          date: '2023-10-18',
          changes: [
            'Add: Error sound effect',
            'Add: Danger sound effect',
            'Add: Alert sound effect',
          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 1rem 0;
}

ul {
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 8px;
  list-style-type: '\2013';
  text-indent: 5px;
}
</style>
